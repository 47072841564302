import { uniqueId } from 'lodash-es'
import { request } from '@/utils'
import type { QuoteList, QuoteListType, RQuoteConfigList } from '@/views/project/list/detail/requirements_new/api'
import type { ProjInfoDesignQuoteCollectFeeSaveDTO } from '@/views/project/list/detail/requirements_new/components/PriceTotalView/types'

export interface PQuote {
  id: string
  listType: QuoteListType[]
}
export interface RQuoteConfig {
  canDown: boolean
  preSaleSure: boolean
  isOpenQuoteShowCostPrice: boolean
  updateVersion: string
  showColumn: string[]
  showColumnList: RQuoteConfigList[]
  watermarkCompany: string
  watermarkCompanyList: RQuoteConfigList[]
  updateVersionList: RQuoteConfigList[]
  showIdentifying: string[]
  identifyingList: RQuoteConfigList[]
}
export default {
  // 查询单个报价清单预览信息
  getQuoteDetail: (id: string) =>
    request.get('/project/project/design/log/getQuoteDetail', {
      params: {
        id,
      },
    }),
  // 查询报价清单设置信息
  getConfig: (projectId: string, schemeId: string) =>
    request.get('/project/project/design/quote/getConfig', {
      params: {
        projectId,
        schemeId,
      },
    }),
  downQuoteFileNormal: (projectId: string, schemeId: string, data: Recordable) =>
    request.post('/project/project/design/quote/downQuoteFileNormal', data, {
      params: {
        projectId,
        schemeId,
      },
      responseType: 'blob',
      timeout: 10 * 60 * 1000,
    }),
  /** 项目需求 */
  // 预览需求详情
  previewProjectRequire: (projectId: string, schemeId: string, siteId: string) =>
    request.get(`/project/project/design/require/browse`, {
      params: {
        projectId,
        schemeId,
        siteId,
      },
    }),
  // 查询项目方案下所有会场
  getSchemeSiteList: (projectId: string, schemeId: string) =>
    request.get('/project/project/design/scheme/getSchemeSiteList', {
      params: {
        projectId,
        schemeId,
      },
    }),
  downRequireFile: (params: Recordable) =>
    request.post('/project/project/design/require/downRequireFile', null, {
      params,
      responseType: 'blob',
      timeout: 10 * 60 * 1000,
    }),
  // 查询单个需求报价清单详情
  getContractInfoById: (projectId: string, requireId: string, dataId: string) =>
    request.get(`/project/project/design/quote/update/detail/${projectId}/${requireId}?dataId=${dataId}`),
  // 查询权限
  getAuth: (projectId: string, schemeId?: string) =>
    request.get(`/project/project/design/require/getAuth?projectId=${projectId}&schemeId=${schemeId}`),
  // 查询报价清单设置信息
  getDownloadConfig: (projectId: string) => request.get(`/project/project/design/quote/update/getConfig/${projectId}`),
  // 查询综合费用修改详情
  getCollectFee: (projectId: string, dataId: string) =>
    request.get(`/project/project/design/quote/update/getCollectFee/${projectId}?dataId=${dataId}`),
  // 下载报价清单文件
  downloadPriceFile: (projectId: string, data: any) =>
    request.post(`/project/project/design/quote/update/downQuoteFile/${projectId}`, data, { responseType: 'blob' }),
  // 查询二级分类或列表是否可以编辑
  getCanEdit: (projectId: string, siteId: string, id: string, type: string, dataId: string, editType = 'delete') =>
    request.get(
      `/project/project/design/quote/update/getCanEdit/${projectId}/${siteId}?id=${id}&type=${type}&dataId=${dataId}&editType=${editType}`
    ),
  // 保存单个需求对应信息
  priceSave: (projectId: string, siteId: string, data: any) =>
    request.post(`/project/project/design/quote/update/save/${projectId}/${siteId}`, data),
  // 保存综合费用
  saveCollectFee: (projectId: string, data: any) => request.post(`/project/project/design/quote/update/saveCollectFee/${projectId}`, data),
  // 合同审批表单数据提交保存
  saveSubmitForm: (
    projectId: string,
    data: {
      dataId: string
      fileList: {
        id: string
        name: string
        refId: string
        status: string
        type: string
        url: string
      }[]
      remark: string
    }
  ) => request.post(`/project/project/design/quote/update/saveSubmitForm?projectId=${projectId}`, data),
  updateTotalAmount: (projectId: string) => request.get(`/project/project/design/quote/update/getAutoQuoteDeviceTotalFee/${projectId}`),
  // 自动计算设备安装总工时
  updateWorkHour: (projectId: string, dataId: string) =>
    request.get(`/project/project/design/quote/update/getAutoQuoteDeviceTotalInstallWorkHour/${projectId}?dataId=${dataId}`),
  getUpdateLogs: (pageNumber: number, pageSize: number, data: any) =>
    request.post(`/project/project/design/quote/update/log/list?pageNumber=${pageNumber}&pageSize=${pageSize}`, data),
  getQuoteDetailById: (id: string) => request.get(`/project/project/design/quote/update/log/getQuoteDetail?id=${id}`),
  getCollectDetailById: (id: string) => request.get(`/project/project/design/quote/update/log/getCollectFeeDetail?id=${id}`),
  // 检查可编辑数量是否合规
  checkCount: (projectId: string, requireId: string, params: any) =>
    request.get(`/project/project/design/quote/update/checkCount/${projectId}/${requireId}`, { params }),
  getRequireList: (projectId: string, dataId: string) =>
    request.get(`/project/project/design/quote/update/getSchemeSite?projectId=${projectId}&dataId=${dataId}`),
  // 查询报价清单预览信息
  getBrowseQuoteAll: (projectId: string) => request.get(`/project/project/design/quote/update/getBrowseQuoteAll/${projectId}`),
  // 查询项目信息
  getProjectInfo: (projectId: string) => request.get(`/project/project/design/quote/update/getProjectInfo?projectId=${projectId}`),
  // 查询提交审核记录
  getSumbitRecord: (data: { projectId: string; schemeId: string; pageNumber: number; pageSize: number }) =>
    request.post(
      `/project/project/design/quote/update/getSumbitRecord?projectId=${data.projectId}&schemeId=${data.schemeId}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,
      data
    ),
  // 查询报价清单提交审核详情
  getSubmitDetail: (params: { projectId: string; verId: string }) =>
    request.get(`/project/project/design/quote/update/getSubmitDetail`, { params }),
  // 查询送审的报价清单
  getSubmitQuoteList: (params: { projectId: string; dataId: string }) =>
    request.get(`/project/project/design/quote/update/getSubmitQuoteList`, { params }),
  // 获取提交审核id
  getSubmitId: (projectId: string, schemeId: string) =>
    request.post(`/project/project/design/quote/update/getSubmitId?projectId=${projectId}&schemeId=${schemeId}`),
  // 查询合同清单标识列表
  getSchemeSiteListToTag: (data: { projectId: string }) =>
    request.get(`/project/project/design/quote/update/getIdentifyingList`, {
      params: {
        projectId: data.projectId,
      },
    }),

  addSite: (siteId: string, projectId: string, dataId: string) =>
    request.post(`/project/project/design/quote/update/addSite?siteId=${siteId}&projectId=${projectId}&dataId=${dataId}`),
  delSite: (siteId: string, projectId: string, dataId: string) =>
    request.post(`/project/project/design/quote/update/deleteSite?siteId=${siteId}&projectId=${projectId}&dataId=${dataId}`),
  getCanAddSiteList: (projectId: string, dataId: string) =>
    request.get(`/project/project/design/quote/update/getCanAddSiteList?dataId=${dataId}&projectId=${projectId}`),
  getEmtpyCollectFee() {
    return {
      // 附加税金费
      additionalTaxFee: 0,
      // 附加税金费率
      additionalTaxRate: 0,
      // 可行性研究报告编制费
      availableReportFee: 0,
      // 设计费用
      designFee: 0,
      // 设备安装措施费费率
      deviceInstallMeasuresRate: 0,
      // 设备安装人工费工时
      deviceInstallWorkHour: 0,
      // 设备安装人工费总价（成本价总价）
      deviceInstallWorkerCostFee: 0,
      // 设备安装人工费单价
      deviceInstallWorkerPrice: 0,
      // 设备购置费
      deviceTotalFee: 0,
      // 设备购置费成本
      deviceCostTotalFee: 0,
      // 管理费用率
      manageRate: 0,
      // 政策性规费
      policyFee: 0,
      // 安全文明施工措施费率
      safeConstructeRate: 0,
      // 监理费率
      supervisionRate: 0,
      // 系统集成费率 50.02传50.02
      systemIntegrationRate: 0,
      // 总包管理费率
      totalPackageManageRate: 0,
      // 运杂费率
      transporteRate: 0,
      // 设备安装措施费计算标准（0：费率；1：金额）
      deviceInstallMeasuresComputeType: '0',
      // 设备安装措施费费
      deviceInstallMeasuresFee: 0,
      // 设备安装人工费单价（成本价）
      deviceInstallWorkerCostPrice: 0,
      // 优惠总价
      finalFee: 0,
      // 管理费计算标准（0：费率；1：金额）
      manageComputeType: '0',
      // 管理费
      manageFee: 0,
      // 安全文明施工措施费计算标准（0：费率；1：金额）
      safeConstructeComputeType: '0',
      // 安全文明施工措施费
      safeConstructeFee: 0,
      // 监理费计算标准（0：费率；1：金额）
      supervisionComputeType: '0',
      // 监理费
      supervisionFee: 0,
      // 总包管理费计算标准（0：费率；1：金额）
      totalPackageManageComputeType: '0',
      // 总包管理费
      totalPackageManageFee: 0,
      // 运杂费率
      transporteComputeType: '0',
      // 运杂费
      transporteFee: 0,
    }
  },
  // 查询报价方案下单个会场报价
  getQuoteBySchemeSite: (params: { projectId: string; scene: '2' | '3'; schemeId: string; siteId: string }) =>
    request.get('/project/project/design/quote/getQuoteBySchemeSite', { params }),
  // 查询报价清单设备报价总金额（除去当前会场的总价）
  getQuoteTotalFeeBySchemeSite: (params: { projectId: string; schemeId: string; siteId: string }) =>
    request.get('/project/project/design/quote/getQuoteTotalFeeBySchemeSite', { params }),
  createQuoteItem: ({
    type1Id,
    type1Name,
    typeId,
    typeName,
    list = [],
  }: {
    type1Id: string
    type1Name: string
    typeId: string
    typeName: string
    list: QuoteList[]
  }) => {
    return {
      id: '',
      uid: uniqueId(),
      local: {
        copy: false,
        paste: false,
        recommend: false,
      },
      selected: false,
      list,
      type1Id,
      type1Name,
      typeId,
      typeName,
    }
  },
  // 查询配套推荐(批量查询)
  getCategoryMateBatch: (data: any, project: string) =>
    request.post(`/project/project/design/category/v2/getCategoryMateV2?project=${project}`, data),
  createQuoteChildItem: (data?: Partial<QuoteList>, paste = false) => {
    return {
      uid: uniqueId(),
      brand: '',
      brandName: '',
      categoryId: '',
      categoryName: '',
      classify: '',
      classifyName: '',
      costPrice: 0,
      costPriceHighlight: false,
      costTotalFee: 0,
      id: '',
      marketPrice: 0,
      marketPriceHighlight: false,
      marketTotalFee: 0,
      model: '',
      modelName: '',
      newestMarketPrice: 0,
      num: 1,
      remark: '',
      rowHighlight: false,
      series: '',
      seriesName: '',
      specsConfig: '',
      technologyParameters: '',
      unit: '',
      unitName: '',
      unitSupportDecimal: false,
      selected: false,
      isCanEdit: true,
      isCanUpdateCategory: true,
      isCanDelete: true,
      local: {
        copy: false,
        paste,
        recommend: false,
      },
      ...data,
    }
  },
  // 保存报价方案下单个会场报价校验
  checkQuote: (data: Partial<PQuote>, projectId: string, schemeId: string, checkType: number) =>
    request.post(
      `/project/project/design/quote/getCheckSaveDataResult?projectId=${projectId}&schemeId=${schemeId}&checkType=${checkType}`,
      data
    ),
  // 保存报价方案下单个会场报价
  saveQuote: (data: Partial<PQuote>, projectId: string, schemeId?: string) =>
    request.post(`/project/project/design/quote/save?projectId=${projectId}&schemeId=${schemeId}`, data),
  // 修改综合费用修改详情
  updateCollectFee: (projectId: string, schemeId: string, data: ProjInfoDesignQuoteCollectFeeSaveDTO) =>
    request.post('/project/project/design/quote/saveCollectFee', data, {
      params: {
        projectId,
        schemeId,
      },
    }),
  downQuoteFile: (projectId: string, schemeId: string, data: Recordable) =>
    request.post('/project/project/design/quote/downQuoteFile', data, {
      params: {
        projectId,
        schemeId,
      },
      responseType: 'blob',
      timeout: 10 * 60 * 1000,
    }),
  // 查询报价清单设置信息
  getQuoteConfig: (params: { projectId: string; schemeId: string }) =>
    request.get<RQuoteConfig>('/project/project/design/quote/getConfig', { params }),
  // 查询模板报价方案下报价
  getQuoteBySchemeSiteTemplate: (params: { projectId: string; schemeId: string; siteId: string; templateId: string }) =>
    request.get('/project/project/design/scheme/template/getQuoteBySchemeSite', { params }),
  // 查询单个货品信息
  getCategoryInfo: (categoryId: string) =>
    request.get<{
      brand: string
      costPrice: number
      id: string
      marketPrice: number
      name: string
      unitCode: string
      unitName: string
    }>(`/project/project/design/category/v2/getCategoryInfo?categoryId=${categoryId}`),
  // 提交品类信息补全申请
  purchaseSupplementNotify: (data: any, project: string, schemeId: string) =>
    request.post(`/project/project/design/quote/purchaseSupplementNotify?projectId=${project}&schemeId=${schemeId}`, data),
  // 保存报价清单模式是否开启查看成本价
  saveOpenQuoteShowCostPrice: (data: { isOpen: boolean }, projectId: string, schemeId?: string) =>
    request.post(`/project/project/design/quote/saveOpenQuoteShowCostPrice?projectId=${projectId}&schemeId=${schemeId}`, data),
  // 保存报价清单可下载设置
  saveDownFileConfig: (data: { isOpen: boolean }, projectId: string, schemeId?: string) =>
    request.post(`/project/project/design/quote/saveDownFileConfig?projectId=${projectId}&schemeId=${schemeId}`, data),
  // 保存售前定稿状态
  savePreSaleSure: (data: { isOpen: boolean }, projectId: string, schemeId?: string) =>
    request.post(`/project/project/design/quote/savePreSaleSure?projectId=${projectId}&schemeId=${schemeId}`, data),
}
